/* src/styles.css */

@import "./0-base/base-dir";

:root {
  /* TODO define colors, radiuses, borders globally */
  --eibe-button-color: #007bff;
  --eibe-button-dark: #0056b3;
  --eibe-background-color: white;
}

[data-amplify-authenticator] {
  --amplify-primary-color: #007bff;
  --amplify-primary-tint: #0056b3;
  --amplify-primary-shade: #2267c4;
  --amplify-background-color: #e0faf4;
  --amplify-components-button-background-color: var(--eibe-button-color);
  --amplify-components-button-color: var(--eibe-background-color);
  --amplify-components-button-primary-background-color: var(--eibe-button-color);
  --amplify-components-button-primary-color: var(--eibe-background-color);
  --amplify-components-button-primary-focus-background-color: var( --eibe-button-color );
  --amplify-components-button-primary-focus-border-color: var( --eibe-button-dark );
  --amplify-components-button-primary-focus-color: var( --eibe-background-color );
  --amplify-components-tabs-item-hover-color: var(--eibe-background-color);
  --amplify-components-tabs-item-active-color: var(--eibe-background-color);
  --amplify-components-tabs-item-active-border-color: var(--eibe-button-dark);
  --amplify-components-tabs-item-active-background-color: var(--eibe-button-color);
}

body {
  font-family: Arial, sans-serif;
  color: #333;
  line-height: 1.6;
}

.button,
button {
  background-color: var(--eibe-button-color);
  color: var(--eibe-background-color);
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px;
  white-space: nowrap;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.button:hover,
button:hover {
  background-color: var(--eibe-button-dark);
}

.button:active,
button:active {
  transform: scale(0.95);
}

.container {
  width: 100%;
  margin: auto;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.15);
  padding: 20px;
}

@media screen and (max-width: var($screen-small)) {
  .container {
    width: 95%;
  }
}
